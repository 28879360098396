import { useMutation } from "@tanstack/vue-query";
import { AxiosError } from "axios";

export interface RegisterVariables {
  nid: string;
  email: string;
  password: string;
  fullName: string;
  phone: string;
  partner?: string | null;
  partnerAdvisor?: string;
  preRegistrationId?: string;
  indicatedBy?: string;
  partnerSlug?: string;
}

interface RegisterBody extends RegisterVariables {
  languageId: string;
  nickName: string;
  source: string;
}

interface RegisterResult {
  accountNotConfirmed: boolean;
}

interface Options {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export const useRegister = (options?: Options) => {
  const { $authApi } = useNuxtApp();

  return useMutation<RegisterResult, Error, RegisterVariables>({
    mutationFn: async (variables) => {
      const body: RegisterBody = {
        ...variables,
        languageId: "br",
        nickName: variables.fullName,
        source: "Plataforma Hurst",
        nid: variables.nid.replace(/\D/g, ""),
      };
      try {
        await $authApi.post("/register", body);
        return { accountNotConfirmed: false };
      } catch (error) {
        if (
          error instanceof AxiosError &&
          error.response?.data?.error === "HCP__ACCOUNT_NOT_CONFIRMED"
        ) {
          return { accountNotConfirmed: true };
        }
        throw error;
      }
    },
    onSuccess: options?.onSuccess,
    onError: options?.onError,
  });
};
